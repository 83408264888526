import { Button } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';
import { useQuery } from '@tanstack/react-query';
import { TFunction } from 'next-i18next';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { validationRegex } from '@/components/helpers/validationRegex';
import { JAPANESE_POSTCODE_CHARACTER_LENGTH } from '@/constants/constants';
import {
	isTokyo23Ward,
	PrefectureName,
	SUPPORTED_PREFECTURE_NAMES,
} from '@/constants/industry/gridOperator';
import { copy } from '@/copy';
import { featureFlags } from '@/features/featureFlags';
import { useIsDirectChannel } from '@/hooks/useIsDirectChannel';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import apiClient from '@/services/api-client';
import graphqlClient, { ErrorCode } from '@/services/graphql-client';
import { PostcodeStatusQuery } from '@/services/graphql-client-tg';
import { PostalAreaFragment } from '@/services/typed-graphql-sdk';
import { CompositionEvent } from '@/types/inputs';
import { fullWidthToHalfWidth } from '@/utils/formatters/fullWidthToHalfWidth';
import { numberHyphenator9000 } from '@/utils/formatters/numberHyphenator9000';
import { removeInvalidPostalAreas } from '@/utils/formatters/removeInvalidPostalAreas';
import { sendQuoteTariffsAnalytics } from '@/utils/googleAnalytics';

export const getPostcodeFieldValidation = (
	t: TFunction
): Yup.StringSchema<string | undefined, object> =>
	Yup.string()
		.trim()
		.required(t('errors.required'))
		.min(JAPANESE_POSTCODE_CHARACTER_LENGTH, t('errors.invalid-postcode'))
		.matches(validationRegex.postcode, t('errors.invalid-postcode'));

export function PostcodeField<
	T extends {
		billingPostalArea: PostalAreaFragment;
		billingPostcode: string;
		isBillingAddressSameAsAddress?: boolean;
		postalArea: PostalAreaFragment;
		postcode: string;
	},
>(
	fieldProps: DomainFieldProps<T> & {
		billing?: boolean;
		label?: string;
		loading?: boolean;
		loadingLabel?: ReactNode | string;
		onPostalArea: (postalArea: PostalAreaFragment) => void;
		variant?: 'default' | 'button';
	}
): ReactElement {
	const {
		values,
		setFieldValue,
		onPostalArea,
		billing,
		label = 'postcode',
		variant = 'default',
		loading,
		loadingLabel,
		disabled = false,
	} = fieldProps;

	const router = useRouter();
	const [, setPostalAreasToLS] = useLocalStorage<PostalAreaFragment[]>(
		'postalAreas',
		[{ area: '', prefecture: '', city: '', postcode: '' }]
	);
	const [, setBillingPostalAreasToLS] = useLocalStorage<PostalAreaFragment[]>(
		'billingPostalAreas',
		[{ area: '', prefecture: '', city: '', postcode: '' }]
	);
	const [, setDualFuelPostcodeStatus] = useLocalStorage<string>(
		'dual-fuel-postcode-status',
		''
	);
	const isDirectChannel = useIsDirectChannel();

	const [postcode, setPostcode] = useState('');
	const [errorMessage, setErrorMessage] = useState<string>('');

	const { data, error, isError } = useQuery({
		queryKey: ['postalArea', postcode],
		queryFn: async () => await graphqlClient.getPostalAreas({ postcode }),
		enabled: !!postcode,
		retry: false,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const urlPostcode = params.get('postcode') as string;
		if (urlPostcode && !billing) {
			setFieldValue('postcode', urlPostcode);
		}
	}, []);

	useEffect(() => {
		if (data?.postalAreas) {
			const postalAreas = data.postalAreas;
			const [postalArea] = postalAreas;
			if (!postalArea) {
				return setErrorMessage(copy.weCouldNotFindYourPostalArea);
			}
			if (
				!SUPPORTED_PREFECTURE_NAMES.includes(
					postalArea.prefecture as PrefectureName
				)
			) {
				return setErrorMessage(copy.weDoNotSupportYourPostalArea);
			}
			removeInvalidPostalAreas(postalArea);
			setErrorMessage('');
			onPostalArea(postalArea);
			if (billing) {
				setBillingPostalAreasToLS(postalAreas as PostalAreaFragment[]);
			} else {
				setPostalAreasToLS(postalAreas as PostalAreaFragment[]);
				if (router.asPath === '/tariffs') {
					setFieldValue('prefecture', postalArea.prefecture);
					sendQuoteTariffsAnalytics();
				}
			}
		}
	}, [data]);

	useEffect(() => {
		if (isError) {
			const errorMsg = new RegExp(ErrorCode.INVALID_POSTCODE).test(
				JSON.stringify(error, Object.getOwnPropertyNames(error))
			)
				? copy.weCouldNotFindYourPostalArea
				: copy.errorProcessingPostcode;
			setErrorMessage(errorMsg);
		}
	}, [isError, error]);

	const checkPostcodeStatusQuery = useQuery({
		queryKey: ['checkPostcodeStatus', postcode],
		queryFn: async (): Promise<PostcodeStatusQuery> =>
			await apiClient.post('/api/tg-kraken/check-postcode-status', {
				postcode: postcode.replace(/-/g, ''),
			}),
		enabled:
			featureFlags.NEXT_PUBLIC_FEATURE_DUAL_FUEL_OBJ &&
			isDirectChannel &&
			!!postcode &&
			!!data?.postalAreas?.[0]?.city &&
			isTokyo23Ward(data.postalAreas[0].city),
		retry: false,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		const postcodeStatus =
			checkPostcodeStatusQuery.data?.postcodeStatus?.status;
		if (postcodeStatus) {
			setDualFuelPostcodeStatus(postcodeStatus);
		}
	}, [checkPostcodeStatusQuery.data]);

	const onValidPostcode = (postcode: string) => {
		setErrorMessage('');
		setPostcode(postcode);
	};

	const onChange = (input: string) => {
		const dashedPostcode = numberHyphenator9000(
			input,
			[3, 4],
			'-' // 151-0071
		);
		setFieldValue(billing ? 'billingPostcode' : 'postcode', dashedPostcode);
		if (
			dashedPostcode.length === JAPANESE_POSTCODE_CHARACTER_LENGTH &&
			variant !== 'button'
		) {
			onValidPostcode(dashedPostcode);
		}
	};

	const onSubmitButtonVariant = (input: string) => {
		const dashedPostcode = numberHyphenator9000(
			input,
			[3, 4],
			'-' // 151-0071
		);

		if (dashedPostcode.length === JAPANESE_POSTCODE_CHARACTER_LENGTH) {
			onValidPostcode(dashedPostcode);
		}
	};

	return (
		<div className="flex flex-col space-y-4">
			<FormikTextField
				inputProps={{
					maxLength: JAPANESE_POSTCODE_CHARACTER_LENGTH,
					inputMode: 'numeric',
					onCompositionEndCapture: (e: CompositionEvent) =>
						onChange(fullWidthToHalfWidth(e.target.value, e.target.maxLength)),
				}}
				attributes={{ 'data-testid': 'postcode-input' }}
				type="search"
				label={label}
				name={billing ? 'billingPostcode' : 'postcode'}
				onChange={(e: { target: HTMLInputElement }) => onChange(e.target.value)}
				validate={async () => (errorMessage ? errorMessage : '')}
				disabled={disabled}
			/>
			{variant === 'button' && (
				<Button
					fullWidth
					color="secondary"
					onClick={() => {
						onSubmitButtonVariant(values.postcode);
					}}
					loading={loading ? true : undefined}
					loadingLabel={loadingLabel}
				>
					{copy.seeAllPlans}
				</Button>
			)}
		</div>
	);
}
