import { useReadSessionStorage } from '@/hooks/useReadSessionStorage';
import { SalesChannelChoices } from '@/services/typed-graphql-sdk';

export const useIsDirectChannel = (): boolean => {
	const affiliateSalesChannel = useReadSessionStorage<string | null>(
		'affiliateSalesChannel'
	);

	/**
	 * Covers situations where affiliateSales channel is null or affiliateSalesChannel === ""
	 */
	return (
		Boolean(affiliateSalesChannel) === false ||
		affiliateSalesChannel === SalesChannelChoices.Direct
	);
};
