import { useTranslation } from 'next-i18next';
import { ReactElement } from 'react';

import { AutocompleteSelectInputField } from '@/components/shared/AutocompleteSelectInputField';
import { PostalAreaFragment } from '@/services/typed-graphql-sdk';

type PostalAreaFieldProps<T> = {
	initialValue?: PostalAreaFragment;
	options: PostalAreaFragment[];
} & Omit<SelectInputFieldProps<T>, 'initialValue' | 'options' | 'placeholder'>;

// Generate a unique value string, for the a given postal area object.
const getPostalAreaValue = (postalArea: PostalAreaFragment): string =>
	`${postalArea.prefecture}${postalArea.city}${postalArea.area}`;

export function PostalAreaField<T>(
	props: PostalAreaFieldProps<T>
): ReactElement {
	const { t } = useTranslation();
	const { initialValue, options, setFieldValue, ...otherProps } = props;

	const handleSetFieldValue = (name: string, value: string) => {
		const postalArea = options.find(
			(option) => getPostalAreaValue(option) === value
		);
		if (postalArea) {
			setFieldValue(name, postalArea);
		}
	};

	return (
		<AutocompleteSelectInputField
			{...otherProps}
			readOnly
			shouldHideLabel
			placeholder={t('obj:inputs.select-postal-area')}
			options={options.map((value) => ({
				value: getPostalAreaValue(value),
				display: getPostalAreaValue(value),
			}))}
			initialValue={getPostalAreaValue(
				initialValue ? initialValue : options[0]
			)}
			//@ts-expect-error
			setFieldValue={handleSetFieldValue}
		/>
	);
}
