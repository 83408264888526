import { FormikTextField } from '@krakentech/coral-formik';
import * as Sentry from '@sentry/nextjs';
import { isFuture, parse } from 'date-fns';
import { TFunction, Trans, useTranslation } from 'next-i18next';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
	ClientDisplayErrorCodes,
	MultiBillingErrorCodes,
	MultiBillingErrorMessages,
	MultiBillingErrorTranslations,
	SentryLoggingErrorCodes,
} from '@/components/helpers/gmo/errors';
import { loadGMOScript } from '@/components/helpers/gmo/loadGMOScript';
import { multiBillingCreditCardToken } from '@/components/helpers/gmo/multiBillingCreditCardToken';
import { useGMOScriptQuery } from '@/components/helpers/gmo/useGMOScriptQuery';
import { validationRegex } from '@/components/helpers/validationRegex';
import { CardDisplay } from '@/components/shared/CardDisplay';
import {
	AMEX_CREDIT_CARD_CVC_DIGITS_LENGTH,
	AMEX_CREDIT_CARD_NUMBER_DIGITS_LENGTH,
	CREDIT_CARD_CVC_DIGITS_LENGTH,
	CREDIT_CARD_EXPIRY_CHARACTER_LENGTH,
	CREDIT_CARD_NUMBER_DIGITS_LENGTH,
} from '@/constants/constants';
import apiClient from '@/services/api-client';
import {
	GenerateCreditCardTokenMutationVariables,
	PaymentTypeChoices,
} from '@/services/typed-graphql-sdk';
import { CompositionEvent } from '@/types/inputs';
import { fullWidthToHalfWidth } from '@/utils/formatters/fullWidthToHalfWidth';
import { numberHyphenator9000 } from '@/utils/formatters/numberHyphenator9000';

type CreditCardFieldsValidation = {
	cardCVC: Yup.StringSchema<string | undefined, object>;
	cardExpiryDate: Yup.StringSchema<string | undefined, object>;
	cardNumber: Yup.StringSchema<string | undefined, object>;
};

function parseMMyyString(expiryString: string) {
	const [month, year] = expiryString.split('/').map((str) => parseInt(str, 10));
	return parse(`01/${month}/${2000 + year}`, 'dd/MM/yyyy', new Date());
}

export const getCreditCardFieldsValidation = (
	t: TFunction
): CreditCardFieldsValidation => {
	return {
		cardNumber: Yup.string()
			.trim()
			.matches(
				validationRegex.creditCardNumber,
				t('errors.invalid-credit-card-number')
			)
			.when('paymentType', {
				is: PaymentTypeChoices.CreditCard,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			}),
		cardExpiryDate: Yup.string()
			.trim()
			.matches(
				validationRegex.creditCardExpiryOnboarding,
				t('errors.invalid-credit-card-expiry')
			)
			.when('paymentType', {
				is: PaymentTypeChoices.CreditCard,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema
						.required(t('errors.required'))
						.test('is-future', t('errors.must-be-future-date'), (value) =>
							Boolean(value && isFuture(parseMMyyString(value)))
						),
			}),
		cardCVC: Yup.string()
			.trim()
			.matches(
				validationRegex.creditCardCVC,
				t('errors.invalid-credit-card-cvc')
			)
			.when('paymentType', {
				is: PaymentTypeChoices.CreditCard,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			}),
	};
};

export function CreditCardFields<
	T extends {
		cardCVC: string;
		cardExpiryDate: string;
		cardNumber: string;
		creditCardToken: string;
	},
>(
	props: DomainFieldProps<T> & {
		onError: (errorMessage: ReactNode) => void;
		onScriptLoadError: () => void;
		onToken: (token: string) => void;
		shouldGetToken: boolean;
	}
): ReactElement {
	const { t } = useTranslation();
	const {
		setFieldValue,
		errors,
		values,
		shouldGetToken,
		onToken,
		onError,
		onScriptLoadError,
	} = props;

	const [scriptQueryEnabled, setScriptQueryEnabled] = useState(false);
	const { gmoScriptObjectURL } = useGMOScriptQuery(scriptQueryEnabled);

	const obtainCreditCardToken = async (gmoScriptObjectURL: string) => {
		const onScriptLoad = async () => {
			try {
				const {
					generateCreditCardToken: { creditCardToken: gmoToken },
				} = await apiClient.get<GenerateCreditCardTokenMutationVariables>(
					'/api/billing/gmo-token'
				);

				await multiBillingCreditCardToken(
					{
						creditCardNumber: values.cardNumber.replace(/ /g, ''),
						creditCardExpiry: values.cardExpiryDate,
						creditCardCVC: values.cardCVC,
					},
					gmoToken
				)
					.then((cardToken) => onToken(cardToken))
					.catch((error) => {
						onMultiBillingCreateTokenErrorResponse(error);
					});
			} catch (err) {
				Sentry.captureMessage(err as string);
				onError(
					<Trans
						i18nKey="errors.error-submitting-payment-details"
						components={{
							a: (
								<a
									className="font-bold underline"
									target="_blank"
									href="/contact-us"
								>
									こちらまでご連絡下さい 。
								</a>
							),
						}}
					/>
				);
			}
		};
		/**
		 * We load this script every time we need it.
		 * Thus, this code might load multiple instances of a similar script element multiple times.
		 */
		loadGMOScript(gmoScriptObjectURL, onScriptLoad, onScriptLoadError);
	};

	const onMultiBillingCreateTokenErrorResponse = (
		errorCode: (typeof MultiBillingErrorCodes)[number]
	) => {
		/** Handle generic errors. */
		if (!errorCode) {
			Sentry.captureMessage('GMO MultiBilling.createToken failed.');
			return onError(
				<Trans
					i18nKey="errors.error-submitting-payment-details"
					components={{
						a: (
							<a
								className="font-bold underline"
								target="_blank"
								href="/contact-us"
							>
								こちらまでご連絡下さい 。
							</a>
						),
					}}
				/>
			);
		}

		/** Handle known GMO configuration errors. */
		if (SentryLoggingErrorCodes.includes(errorCode)) {
			Sentry.captureMessage('GMO MultiBilling.createToken failed.', {
				extra: {
					errorCode,
					errorMessage: MultiBillingErrorMessages[errorCode],
				},
			});
			return onError(
				<Trans
					i18nKey="errors.error-submitting-payment-details"
					components={{
						a: (
							<a
								className="font-bold underline"
								target="_blank"
								href="/contact-us"
							>
								こちらまでご連絡下さい 。
							</a>
						),
					}}
				/>
			);
		}
		/** Handle known GMO validation errors with translations. */
		if (ClientDisplayErrorCodes.includes(errorCode)) {
			return onError(MultiBillingErrorTranslations[errorCode]);
		}
		/** @todo: handle field input errors */
		return onError(
			<Trans
				i18nKey="errors.error-submitting-payment-details"
				components={{
					a: (
						<a
							className="font-bold underline"
							target="_blank"
							href="/contact-us"
						>
							こちらまでご連絡下さい 。
						</a>
					),
				}}
			/>
		);
	};

	/**
	 * As soon as credit card fields are filled and valid, we request the card
	 * token from GMO and set the 'cardToken' field in the form upon success.
	 */
	useEffect(() => {
		const areCreditCardFieldsValid =
			values.cardNumber &&
			!errors.cardNumber &&
			values.cardExpiryDate &&
			!errors.cardExpiryDate &&
			values.cardCVC &&
			!errors.cardCVC;

		if (areCreditCardFieldsValid && shouldGetToken) {
			gmoScriptObjectURL
				? obtainCreditCardToken(gmoScriptObjectURL)
				: setScriptQueryEnabled(true);
		}
	}, [shouldGetToken, gmoScriptObjectURL]);

	const onCardNumberChange = (value: string) => {
		const formattedCardNumber = numberHyphenator9000(value, [4, 4, 4, 4], ' ');
		setFieldValue('cardNumber', formattedCardNumber);
	};

	const onExpiryDateChange = (value: string) => {
		const slashedCardExpiryDate = numberHyphenator9000(value, [2, 2], '/');
		setFieldValue('cardExpiryDate', slashedCardExpiryDate);
	};

	return (
		<div className="space-y-4">
			<FormikTextField
				inputProps={{
					maxLength:
						(values.cardNumber.match(validationRegex.isAMEXCreditCardNumber)
							? AMEX_CREDIT_CARD_NUMBER_DIGITS_LENGTH
							: CREDIT_CARD_NUMBER_DIGITS_LENGTH) + 3,
					inputMode: 'numeric',
					onCompositionEndCapture: (e: CompositionEvent) =>
						onCardNumberChange(
							fullWidthToHalfWidth(e.target.value, e.target.maxLength)
						),
				}}
				label={t('inputs.credit-card-number')}
				name="cardNumber"
				onChange={(e: { target: HTMLInputElement }) =>
					onCardNumberChange(e.target.value)
				}
				theme="dark"
				startIcon={
					<CardDisplay
						cardNumber={values.cardNumber.replace(/ /g, '')}
						showScheme={false}
					/>
				}
			/>
			<FormikTextField
				inputProps={{
					maxLength: CREDIT_CARD_EXPIRY_CHARACTER_LENGTH,
					inputMode: 'numeric',
					onCompositionEndCapture: (e: CompositionEvent) =>
						onExpiryDateChange(
							fullWidthToHalfWidth(e.target.value, e.target.maxLength)
						),
				}}
				label={t('inputs.credit-card-expiry')}
				name="cardExpiryDate"
				onChange={(e: { target: HTMLInputElement }) =>
					onExpiryDateChange(e.target.value)
				}
				theme="dark"
			/>
			<FormikTextField
				inputProps={{
					maxLength: values.cardNumber.match(
						validationRegex.isAMEXCreditCardNumber
					)
						? AMEX_CREDIT_CARD_CVC_DIGITS_LENGTH
						: CREDIT_CARD_CVC_DIGITS_LENGTH,
					inputMode: 'numeric',
					onCompositionEndCapture: (e: CompositionEvent) =>
						setFieldValue(
							'cardCVC',
							fullWidthToHalfWidth(e.target.value, e.target.maxLength)
						),
				}}
				label={t('inputs.credit-card-cvc')}
				name="cardCVC"
				theme="dark"
			/>
		</div>
	);
}
