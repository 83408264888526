import { Grid } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';
import { TFunction, useTranslation } from 'next-i18next';
import { ReactElement, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { PostalAreaField } from '@/components/forms/fields/PostalAreaField';
import { PostcodeField } from '@/components/forms/fields/PostcodeField';
import { RadioWithBorder } from '@/components/shared/RadioWithBorder';
import {
	OCCTO_ADDRESS_LINE_1_MAX_LENGTH,
	OCCTO_ADDRESS_LINE_2_MAX_LENGTH,
} from '@/constants/constants';
import { getGridOperatorMaximumBuildingNameLengthFromGridOperatorCode } from '@/constants/industry/gridOperator';
import { PostalArea } from '@/services/typed-graphql-sdk';

function validateAddressLength(...parts: (string | undefined | null)[]) {
	return parts.filter((v) => v).join('').length <= 36;
}

type AddressFieldsValidation = {
	addressLine1: Yup.StringSchema<string, object>;
	buildingName: Yup.StringSchema<string | undefined, object>;
	buildingNumber: Yup.StringSchema<string | undefined, object>;
	roomNumber: Yup.StringSchema<string | undefined, object>;
};

export const getAddressFieldsValidation = (
	t: TFunction
): AddressFieldsValidation => {
	return {
		addressLine1: Yup.string()
			.trim()
			.required(t('errors.required'))
			.max(
				OCCTO_ADDRESS_LINE_1_MAX_LENGTH,
				t('errors.invalid-address-line1', {
					number: OCCTO_ADDRESS_LINE_1_MAX_LENGTH,
				})
			),
		buildingName: Yup.string()
			.trim()
			.when('propertyType', {
				is: 'apartment',
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			})
			.test({
				name: 'validateBuildingNameLength',
				test(value) {
					const length = value?.length ? value.length : 0;

					const maxBuildingNameLength =
						getGridOperatorMaximumBuildingNameLengthFromGridOperatorCode(
							this.parent.gridOperatorCodeFromPostalArea
						);
					return length > maxBuildingNameLength
						? this.createError({
								message: t('errors.invalid-address-line2', {
									number: maxBuildingNameLength,
								}),
								path: 'buildingName',
							})
						: true;
				},
			}),
		roomNumber: Yup.string()
			.trim()
			.when('propertyType', {
				is: 'apartment',
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			})
			.test(
				'roomNumber',
				t('errors.invalid-address-line2', {
					number: OCCTO_ADDRESS_LINE_2_MAX_LENGTH,
				}),
				function (value) {
					return validateAddressLength(
						value,
						this.parent.buildingNumber,
						this.parent.buildingName
					);
				}
			),
		buildingNumber: Yup.string().test(
			'buildingNumber',
			t('errors.invalid-address-line2', {
				number: OCCTO_ADDRESS_LINE_2_MAX_LENGTH,
			}),
			function (value) {
				return validateAddressLength(
					value,
					this.parent.buildingName,
					this.parent.roomNumber
				);
			}
		),
	};
};

export type PropertyType = 'apartment' | 'detachedHouse';

type AddressFieldsProps<T> = {
	billing?: boolean;
} & DomainFieldProps<T>;

export function AddressFields<
	T extends {
		addressLine1: string;
		billingPostalArea: PostalArea;
		billingPostcode: string;
		buildingName: string;
		buildingNumber: string;
		postalArea: PostalArea;
		postcode: string;
		propertyType: PropertyType;
		roomNumber: string;
	},
>(
	props: AddressFieldsProps<T> & {
		disableChangingPostcode: boolean;
		onPostalArea?: (postalArea: PostalArea) => void;
		postalAreas: PostalArea[];
		shouldEnterTownArea: boolean;
	}
): ReactElement {
	const {
		onPostalArea,
		values,
		billing = false,
		postalAreas,
		shouldEnterTownArea,
		disableChangingPostcode,
	} = props;
	const { t } = useTranslation();

	const [showPostalArea, setShowPostalArea] = useState(false);
	useEffect(() => {
		setShowPostalArea(!billing && Boolean(values.postalArea.postcode));
	}, [values.postalArea.postcode]);

	return (
		<>
			<Grid gap="sm" templateColumns="repeat(2, 1fr)">
				<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
					<PostcodeField
						label={t('postcode')}
						billing={billing}
						disabled={disableChangingPostcode}
						{...props}
						onPostalArea={(postalArea) => {
							if (onPostalArea) {
								onPostalArea(postalArea);
							}
						}}
					/>
				</Grid.Item>
				<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
					{showPostalArea && (
						<PostalAreaField
							name="postalArea"
							initialValue={values.postalArea}
							options={postalAreas}
							{...props}
						/>
					)}
				</Grid.Item>
			</Grid>
			{shouldEnterTownArea && (
				<FormikTextField
					label={t('obj:inputs.town-area')}
					name="postalArea.area"
				/>
			)}
			<FormikTextField
				label={t('obj:inputs.address-line-1')}
				name="addressLine1"
			/>
			<RadioWithBorder
				name="propertyType"
				radioValues={[
					{
						label: t('obj:inputs.apartment'),
						value: 'apartment',
					},
					{
						label: t('obj:inputs.detached-house'),
						value: 'detachedHouse',
					},
				]}
			/>
			{values.propertyType === 'apartment' ? (
				<>
					<FormikTextField
						label={t('obj:inputs.building-name')}
						name="buildingName"
					/>
					<Grid gap="sm" templateColumns="repeat(2, 1fr)">
						<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
							<FormikTextField
								label={t('obj:inputs.building-number')}
								name="buildingNumber"
								optional
							/>
						</Grid.Item>
						<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
							<FormikTextField
								label={t('obj:inputs.room-number')}
								name="roomNumber"
							/>
						</Grid.Item>
					</Grid>
				</>
			) : null}
		</>
	);
}
