import { FC } from 'react';

import { cardRegexByType } from '@/components/helpers/validationRegex';
import { cardIconByType, cardNameByType, CardType } from '@/constants/payments';

function cardTypeFromNumber(number: string): CardType {
	for (const [cardType, regex] of Object.entries(cardRegexByType)) {
		if (regex.test(number)) {
			return cardType as unknown as CardType;
		}
	}

	return CardType.OTHER;
}

export const CardDisplay: FC<{ cardNumber: string; showScheme?: boolean }> = ({
	cardNumber,
	showScheme = true,
}) => {
	const cardType = cardTypeFromNumber(cardNumber);
	const Icon = cardIconByType[cardType];

	return (
		<>
			{showScheme && <span className="mr-2">{cardNameByType[cardType]}</span>}
			<span className="inline-block align-text-bottom">
				<Icon />
			</span>
		</>
	);
};
