import { useCallback, useEffect, useState } from 'react';

import { SessionKey } from '@/constants/constants';
import { useEventListener } from '@/hooks/useEventListener';

type Value<T> = T | null;

/**
 * Adapted from usehooks-ts useReadLocalStorage, to instead use Session Storage.
 *
 * See: https://usehooks-ts.com/react-hook/use-read-local-storage
 */
export function useReadSessionStorage<T>(key: SessionKey): Value<T> {
	// Get from session storage then
	// parse stored json or return initialValue
	const readValue = useCallback((): Value<T> => {
		// Prevent build error "window is undefined" but keep keep working
		if (typeof window === 'undefined') {
			return null;
		}

		try {
			const item = window.sessionStorage.getItem(key);
			return item ? (JSON.parse(item) as T) : null;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn(`Error reading sessionStorage key “${key}”:`, error);
			return null;
		}
	}, [key]);

	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState<Value<T>>(readValue);

	// Listen if sessionStorage changes
	useEffect(() => {
		setStoredValue(readValue());
	}, []);

	const handleStorageChange = useCallback(() => {
		setStoredValue(readValue());
	}, [readValue]);

	// this only works for other documents, not the current one
	useEventListener('storage', handleStorageChange);

	// this is a custom event, triggered in writeValueToSessionStorage
	// See: useSessionStorage()
	useEventListener('session-storage', handleStorageChange);

	return storedValue;
}
