import { isAfter, isBefore } from 'date-fns';

import {
	GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME,
	GridOperatorCode,
	isTieredCapacityGridOperator,
} from '@/constants/industry/gridOperator';
import {
	AccountQuery,
	Agreement,
	ElectricitySupplyPoint,
	SupplyPointStatus,
} from '@/services/typed-graphql-sdk';

export function gridOperatorCodeFromSPIN(spin: string): GridOperatorCode {
	return spin.slice(0, 2) as GridOperatorCode;
}

export const canUseSameContractCapacity = (
	gridOperatorCodeFromPostalArea: GridOperatorCode,
	gridOperatorCodeFromSPIN: GridOperatorCode
): boolean => {
	const doesGridOperatorFromSPINNeedContractCapacity =
		gridOperatorCodeFromSPIN !== GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME.OEPC;
	const doesGridOperatorFromPrefectureNeedContractCapacity =
		gridOperatorCodeFromPostalArea !==
		GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME.OEPC;
	const doesContractCapacityDifferBetweenPrefectureAndSPIN =
		isTieredCapacityGridOperator(gridOperatorCodeFromPostalArea) !==
		isTieredCapacityGridOperator(gridOperatorCodeFromSPIN);

	return (
		!gridOperatorCodeFromSPIN ||
		!doesGridOperatorFromSPINNeedContractCapacity ||
		(doesGridOperatorFromPrefectureNeedContractCapacity &&
			!doesContractCapacityDifferBetweenPrefectureAndSPIN)
	);
};

/**
 * @returns Whether or not the given account has at least one supply point
 *   with at least one of the given statuses that has an agreement that:
 *     - begins on or before the given fromDateInclusive,
 *     - does not finish before the given fromDateInclusive, and
 *     - (if there is a given toDateExclusive)
 *       - does not conclude before the given toDateExclusive.
 *   I.e. "Does this account have {any of the given statuses} at the given time?"
 */
export const hasSupplyPointStatus = ({
	account,
	fromDateInclusive,
	statuses,
	toDateExclusive,
}: {
	account: AccountQuery['account'];
	fromDateInclusive: Date;
	statuses: SupplyPointStatus[];
	toDateExclusive?: Date;
}): boolean =>
	account?.properties?.[0]?.electricitySupplyPoints?.some(
		(supplyPoint) =>
			supplyPoint?.status &&
			statuses.includes(supplyPoint?.status) &&
			supplyPoint?.agreements.some((agreement) => {
				const doesAgreementStartOnOrBeforeFromDate =
					agreement?.validFrom &&
					!isAfter(new Date(agreement.validFrom), fromDateInclusive);
				const doesAgreementFinishBeforeFromDate =
					agreement?.validTo &&
					isBefore(new Date(agreement.validTo), fromDateInclusive);
				const doesAgreementFinishBeforeToDate =
					agreement?.validTo &&
					toDateExclusive &&
					isBefore(new Date(agreement.validTo), toDateExclusive);
				return (
					doesAgreementStartOnOrBeforeFromDate &&
					!doesAgreementFinishBeforeFromDate &&
					!doesAgreementFinishBeforeToDate
				);
			})
	) ?? false;

export const getMostRecentAgreement = (
	agreements: Maybe<Agreement>[] | undefined
): Maybe<Agreement> => {
	if (!agreements?.length) {
		return null;
	}

	return agreements.reduce((latest, current) => {
		if (!current) {
			return latest;
		}
		return current.validFrom > (latest?.validFrom || '') ? current : latest;
	}, null);
};

export const getElectricitySupplyPointWithMostRecentAgreement = (
	electricitySupplyPoints: ElectricitySupplyPoint[]
): ElectricitySupplyPoint | undefined => {
	const validSupplyPoints = electricitySupplyPoints.filter(
		({ status }) =>
			status === SupplyPointStatus.OnSupply ||
			status === SupplyPointStatus.MoveOutPending ||
			status === SupplyPointStatus.SwitchOutPending ||
			status === SupplyPointStatus.Lost
	);

	return validSupplyPoints.length
		? validSupplyPoints.reduce((winnerSoFar, candidate) =>
				isAfter(
					new Date(getMostRecentAgreement(candidate.agreements)?.validFrom),
					new Date(getMostRecentAgreement(winnerSoFar.agreements)?.validFrom)
				)
					? candidate
					: winnerSoFar
			)
		: undefined;
};
