import { Grid } from '@krakentech/coral';
import {
	FormikDatePicker,
	FormikSelect,
	FormikTextField,
} from '@krakentech/coral-formik';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';

import { placeholders } from '@/components/helpers/placeholders';
import { validationRegex } from '@/components/helpers/validationRegex';
import { getEarliestGasMoveInDate } from '@/components/pages/join/validationSchema';
import { SubHeading } from '@/components/shared/SubHeading';
import { MOBILE_NUMBER_LENGTH } from '@/constants/constants';
import { JoinFormValues } from '@/data/join';
import { CompositionEvent } from '@/types/inputs';
import { fullWidthToHalfWidth } from '@/utils/formatters/fullWidthToHalfWidth';
import { numberHyphenator9000 } from '@/utils/formatters/numberHyphenator9000';

export type DualFuelFieldsMoveInProps = {
	formikProps: FormikProps<JoinFormValues>;
};

/**
 * @note from Move in CSV interface
 * 1：午前
 * 3：13:00～15:00
 * 5：15:00～17:00
 * 7：17:00～19:00
 */
type GasVisitTime = '1' | '3' | '5' | '7';
export const GasVisitTimes: GasVisitTime[] = ['1', '3', '5', '7'];

/**
 * @note from Move in CSV interface
 * 1：契約者ご本人（または配偶者）
 * 4：家主・管理人
 * 9：その他
 */
type GasAttendantOption = '1' | '4' | '9';
export const GasAttendantOptions: GasAttendantOption[] = ['1', '4', '9'];

/**
 * Fields to be displayed for the Dual Fuel product with moving-in selected on the OBJ
 */
export const DualFuelFieldsMoveIn: FC<DualFuelFieldsMoveInProps> = ({
	formikProps,
}) => {
	const { i18n, t } = useTranslation();

	const onMobileInputChange = (value: string) => {
		const hyphenatedMobileNumberInput = numberHyphenator9000(value, [3, 4, 4]);
		formikProps.setFieldValue(
			'gasMoveVisitAttendantMobile',
			hyphenatedMobileNumberInput
		);
	};

	/**
	 * @note
	 * Customers need to provide the name and telephone number of the
	 * gas attendee, if they are not the contract-holder - "契約者本人"
	 */
	const shouldShowExtraAttendeeFields = Boolean(
		formikProps.values?.gasMoveVisitAttendantSelection?.value &&
			formikProps.values.gasMoveVisitAttendantSelection?.value !== '1'
	);

	const gasMoveVisitTimeOptions: Array<{ label: string; value: GasVisitTime }> =
		[
			{ label: t('obj:your-details-gas.morning'), value: '1' },
			{ label: '13:00 ~ 15:00', value: '3' },
			{ label: '15:00 ~ 17:00', value: '5' },
		];

	const gasMoveVisitAttendantOptions: Array<{
		label: string;
		value: GasAttendantOption;
	}> = [
		{
			label: t('obj:your-details-gas.contract-holder'),
			value: '1',
		},
		{
			label: t('obj:your-details-gas.landlord-manager'),
			value: '4',
		},
		{ label: t('obj:your-details-gas.other'), value: '9' },
	];

	/**
	 * Temporary fix for Coral FormikDatePicker error:
	 * Permanent fix in Coral v 21: https://github.com/octopus-energy/coral/pull/3529
	 * issue: "uncontrolled to controlled error"
	 */
	const [isControlledSettled, setIsControlledSettled] = useState(false);
	useEffect(() => {
		if (formikProps.values.gasMoveVisitDate) {
			formikProps.setFieldValue('gasMoveVisitDate', '');
		}
		setIsControlledSettled(true);
	}, []);

	return (
		<>
			<SubHeading text={t('obj:your-details-gas.header-moving')} />
			<p className="md:text-lg">{t('obj:your-details-gas.subheader')}</p>
			{isControlledSettled && (
				<FormikDatePicker
					minDate={getEarliestGasMoveInDate()}
					fullWidth
					name="gasMoveVisitDate"
					label={t('obj:your-details-gas.move-visit-date-label')}
					locale={i18n.language === 'en' ? 'en-GB' : 'ja'}
				/>
			)}
			<FormikSelect
				label={t('obj:your-details-gas.move-visit-time-label')}
				name="gasMoveVisitTime"
				values={gasMoveVisitTimeOptions}
			/>
			<FormikSelect
				label={t('obj:your-details-gas.move-visit-attendant-select-label')}
				name="gasMoveVisitAttendantSelection"
				values={gasMoveVisitAttendantOptions}
			/>
			{shouldShowExtraAttendeeFields && (
				<>
					<SubHeading
						text={t('obj:your-details-gas.attendant-fields-subheader')}
					/>
					<Grid gap="sm" templateColumns="repeat(2, 1fr)">
						<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
							<FormikTextField
								label={t('obj:inputs.last-name')}
								name="gasMoveVisitAttendantLastNameKanji"
							/>
						</Grid.Item>
						<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
							<FormikTextField
								label={t('obj:inputs.first-name')}
								name="gasMoveVisitAttendantFirstNameKanji"
							/>
						</Grid.Item>
					</Grid>
					<FormikTextField
						name="gasMoveVisitAttendantMobile"
						placeholder={placeholders.mobile}
						label={t('obj:your-details.mobile-number')}
						type="tel"
						inputProps={{
							inputMode: 'numeric',
							pattern: validationRegex.isJapanMobileNumber.source,
							maxLength: MOBILE_NUMBER_LENGTH,
							onCompositionEndCapture: (e: CompositionEvent) =>
								onMobileInputChange(
									fullWidthToHalfWidth(e.target.value, e.target.maxLength)
								),
						}}
						onChange={(e: { target: HTMLInputElement }) =>
							onMobileInputChange(e.target.value)
						}
					/>
				</>
			)}
		</>
	);
};
