import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import apiClient from '@/services/api-client';

export const useGMOScriptQuery = (
	enabled: boolean
): {
	gmoScriptObjectURL: string | undefined;
} => {
	const { data, error } = useQuery({
		queryKey: ['obtainGmoScriptObjectURL'],
		queryFn: async () => {
			const { script } = await apiClient.get<{ script: string }>(
				'/api/billing/gmo-script'
			);
			return URL.createObjectURL(new Blob([script]));
		},
		enabled,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (error) {
			Sentry.captureMessage('Error occurred in the gmo-script request.', {
				extra: { error },
			});
		}
	}, [error]);

	return { gmoScriptObjectURL: data };
};
