/**
 *
 * Uses the GMO MultiBilling SDK to produce a credit card token.
 *
 * 1. Registers key (set in env) to GMO using MultiBilling.setPublishableKey
 * 2. Defines / redefines a responseHandler in the window object for GMO to call from the MultiBilling SDK
 * 3. Obtains a credit card token through the MultiBilling.createToken function
 *
 * @param creditCardValues The values with which to creed obtain the credit card token.
 * @param gmoToken The GMO token generated by Kraken.
 * @returns a Promise containing the credit card token as a string.
 */
export const multiBillingCreditCardToken = async (
	creditCardValues: CreditCardFormFields,
	gmoToken: string
): Promise<string> =>
	new Promise((resolve, reject) => {
		/* Set a publishable key with GMO MultiBilling  */
		const GMOMultiBillingPublicKey = process.env
			.NEXT_PUBLIC_GMO_MULTIBILLING_PUBLIC_KEY as string;
		if (!GMOMultiBillingPublicKey) {
			reject(['NEXT_PUBLIC_GMO_MULTIBILLING_PUBLIC_KEY not found.']);
		}

		window.MultiBilling.setPublishableKey(GMOMultiBillingPublicKey as string);

		/* Create a response handler to pass to MultiBilling  */
		/**
		 *
		 * A callback sent to the MultiBilling.createToken method,
		 * which handles the response from GMO.
		 *
		 * ⚠️ WARNING ⚠️
		 *
		 * DO NOT refactor this function declaration (function responseHandler...)
		 * into a function expression (const responseHandler...)
		 *
		 * The MultiBilling.createToken implementation relies on
		 * the responseHandler's name property which is defined only in function
		 * declarations.
		 *
		 * We also know that the GMO script evaluates this function using eval(),
		 * this working implementation might be brittle to refactors.
		 *
		 * @param httpStatus The status of the MultiBilling.createToken response.
		 * @param response The response containing the token or error codes.
		 */
		function responseHandler(
			httpStatus: number,
			response: MultiBillingCreateTokenResponse
		) {
			/* Handle success */
			if (httpStatus === 200 && !response.error_code) {
				const token = response.id;
				return resolve(token as string);
			}
			/* Handle failures by error_code in calling function */
			return reject(response.error_code);
		}

		/**
		 * Define responseHandler in window
		 *
		 * Bear with me here. 👿
		 *
		 * As discussed above, GMO does some evil (eval) bs inside of their
		 * MultiBilling.createToken function. It will attempt to find a
		 * function named "responseHandler" in the global scope to call.
		 *
		 * After calling it, it seems to replace "responseHandler" with
		 * an empty shell, but keeps the name reference alive; this is
		 * why we have to delete it first, otherwise we won't be able
		 * to redefine the function.
		 */
		try {
			delete window.responseHandler;
			Object.defineProperty(window, responseHandler.name, {
				value: responseHandler,
				configurable: true, // Permits deletion
			});
		} catch (error) {
			reject(error);
		}

		/** Register the new credit card information (creates a token) with GMO MultiBilling */
		const expMonth = creditCardValues.creditCardExpiry.slice(0, 2);
		/* Note: the year may be either 2 or 4 digits (YY or YYYY)
		 * Getting as a slice of the last 2 chars takes care of both inputs */
		const expYear = creditCardValues.creditCardExpiry.slice(-2);
		const creditCardNumber = creditCardValues.creditCardNumber.replace(
			/-/g,
			''
		);

		window.MultiBilling.createToken(
			{
				number: creditCardNumber,
				cvc: creditCardValues.creditCardCVC,
				exp_month: expMonth,
				exp_year: `20${expYear}`,
				token: gmoToken as string,
			},
			responseHandler
		);
	});
