import { ErrorMessage } from '@krakentech/coral';
import { FormikRadio } from '@krakentech/coral-formik';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

export const RadioWithBorder: FC<{
	error?: string;
	formikValues?: Record<string, unknown>;
	name: string;
	radioValues: {
		content?: ReactNode;
		disabled?: boolean;
		label: string;
		value: string;
	}[];
	theme?: 'dark' | 'light';
}> = ({ formikValues, error, name, radioValues, theme = 'light' }) => {
	return (
		<fieldset className="space-y-4" data-testid="radio-with-border">
			{radioValues.map((ele) => {
				return (
					<div key={ele.value}>
						<div
							className={clsx('grid rounded-xl border-2 p-3', {
								'border-error': error,
								'border-ink': !error && !ele?.disabled,
								'border-purplehaze': ele?.disabled,
							})}
						>
							<FormikRadio name={name} theme={theme}>
								{[ele].map((option) => (
									<FormikRadio.Option
										key={option.value}
										value={option.value}
										disabled={option?.disabled}
									>
										{option.label}
									</FormikRadio.Option>
								))}
							</FormikRadio>
						</div>
						{formikValues?.[name] === ele.value && ele.content ? (
							<div className="mx-4 mt-4" data-testid="radio-with-fields">
								{ele.content}
							</div>
						) : null}
					</div>
				);
			})}
			{error ? <ErrorMessage message={error} /> : null}
		</fieldset>
	);
};
