export const MultiBillingErrorCodes = [
	'E4000000',
	'E4010000',
	'E4010001',
	'E4040000',
	'E5000000',
	'E5005000',
	'E4001159',
	'E4001144',
	'E4001145',
	'E4001157',
	'E4001146',
	'E4001158',
	'E4001148',
	'E4001150',
	'E4001151',
	'E4021001',
	'E4021002',
	'E4021003',
	'E4021004',
	'E4021005',
	'E4021006',
	'E4021007',
	'E4041501',
	'E4041502',
	'E4041503',
	'E4041504',
	'E4041507',
	'E5001000',
] as const;

export const MultiBillingErrorMessages: Record<
	(typeof MultiBillingErrorCodes)[number],
	string
> = {
	E4000000: 'Request format is not appropriate.',
	E4010000: 'Authentication key is empty.',
	E4010001: 'Right authentication key is to be set.',
	E4040000: 'URL is wrongly specified.',
	E5000000: 'System error occurred.',
	E5005000: 'System error occurred.',
	E4001159: 'Card number is mandatory..',
	E4001144: 'The card number exceeds the maximum length.',
	E4001145: 'The format of the card number is invalid.',
	E4001157: 'Expiration date (month) is mandatory.',
	E4001146: 'The expiration date (month) format is invalid.',
	E4001158: 'Expiration (year) is mandatory.',
	E4001148: 'The expiration date (year) format is invalid.',
	E4001150: 'The security code exceeds the maximum length.',
	E4001151: 'The format of the security code is invalid.',
	E4021001: 'The card expiration date is incorrect.',
	E4021002: 'The card number is incorrect.',
	E4021003: "This card is over the limit, so you can't use it.",
	E4021004: 'This card cannot be used. Contact the credit card company.',
	E4021005: 'This card has been canceled. ',
	E4021006: 'This card cannot be used. Contact the credit card company.',
	E4021007: 'The security code is incorrect.',
	E4041501: 'The token ID has been deleted.',
	E4041502: 'Unregistered token ID.',
	E4041503: 'Token has been expired.',
	E4041504: 'The token ID is in use.',
	E4041507: 'The card information is set for the token ID.',
	E5001000: 'A system error occurred at the card company.',
};

/**
 * @note
 * Test credit card numbers:
 *
 * Credit card number | Error codes | Error messages
 * 4999999999999901   | E4021001    | The expiration date on the card is invalid.
 * 4999999999999902   | E4021002    | The card number is incorrect.
 * 4999999999999903   | E4021003    | This card is over the amount limit and cannot be used.
 * 4999999999999904   | E4021004    | This card is not accepted. Contact your credit card company.
 * 4999999999999905   | E4021005    | This card has been canceled.
 * 4999999999999906   | E4021006    | This card is not accepted. Contact your credit card company.
 * 4999999999999907   | E4021007    | The security code is incorrect.
 * 4999999999999950   | E5001000    | A system error has occurred at the credit card company.
 *
 * See: GMO MultiBilling Doc: https://drive.google.com/drive/u/0/folders/1hDMxBoxRMRNij6MlB1vKtC9AYi8dRzck p:82
 */
export const MultiBillingErrorTranslations: Partial<
	Record<(typeof MultiBillingErrorCodes)[number], string>
> = {
	/** The expiration date on the card is invalid. */
	E4021001: 'カードの有効期限が正しくありません。',
	/** The card number is incorrect. */
	E4021002: 'カード番号が正しくありません。',
	/** This card is over the amount limit and cannot be used. */
	E4021003: 'このカードは限度額オーバーのため利用できません。',
	/** This card is not accepted. Contact your credit card company. */
	E4021004: 'このカードは利用できません。カード会社にお問合せください。',
	/** This card has been canceled. */
	E4021005: 'このカードは解約済みです。',
	/** This card is not accepted. Contact your credit card company. */
	E4021006: 'このカードは利用できません。カード会社にお問合せください。',
	/** The security code is incorrect. */
	E4021007: 'セキュリティコードが正しくありません。',
	/** A system error has occurred at the credit card company. */
	E5001000: 'カード会社にてシステムエラーが発生しました。',
};

/** These translated errors are grouped for displaying to the user. */
export const ClientDisplayErrorCodes: Partial<
	(typeof MultiBillingErrorCodes)[number][]
> = [
	'E4021001',
	'E4021002',
	'E4021003',
	'E4021004',
	'E4021005',
	'E4021006',
	'E4021007',
	'E5001000',
];

/** These system errors are grouped for logging to sentry. */
export const SentryLoggingErrorCodes: Partial<
	(typeof MultiBillingErrorCodes)[number][]
> = ['E4000000', 'E4010000', 'E4010001', 'E4040000', 'E5000000', 'E5005000'];

/** These errors are grouped by code to display field input specific errors in the input form. */
export const FieldInputByErrorCode: Partial<
	Record<(typeof MultiBillingErrorCodes)[number], string>
> = {
	E4021001: 'creditCardExpiry',
	E4021002: 'creditCardNumber',
	E4021007: 'creditCardCVC',
};
