import { featuresConfig } from '@/features/features.config';

export type FeatureName = keyof typeof featuresConfig;

/**
 * Exports a type-safe featureFlags object
 * based on the features configuration file.
 *
 * @example
 * withPageFeatureEnabled(Feature, "THIS_FLAG_DOES_NOT_EXIST_IN_CONFIG") // ❌ ts-error
 * withPageFeatureEnabled(Feature, "THIS_FLAG_EXISTS_IN_CONFIG") // ✅
 */
export const featureFlags = featuresConfig;
