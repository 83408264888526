export const placeholders = {
	lastNameKanji: '蛸' /* "Octopus" */,
	lastNameKana: 'タコ' /* "Octopus" */,
	firstNameKanji: '太郎' /* "Taro" */,
	firstNameKana: 'タロウ' /* "Taro" */,
	email: 'constantine@octopus.energy',
	postcode: '103-0015' /* TGOE office postcode */,
	addressLine1: '4-2',
	addressLine2: 'オクトパスビル　201号室' /* "Octopus Building, Room 201" */,
	mobile: '070-0000-0000 (半角数字)',
	date: '1988/08/08',
	currentSupplier: '東京電力エナジーパートナー' /* "TEPCO energy partner" */,
	customerNumber: '000000000000000',
	spin: '03-0000-0000-0000-0000-0000',
	bankAccountHolderName: 'オクトパス　タロウ' /* "Octopus Taro" */,
	bankCode: '入力または選択...' /* "Type or select..." */,
	creditCardNumber: 'カード番号',
	creditCardExpiry: '12/25',
	monthlyUsage: '例: 200',
	password: 'パスワード',
	confirmPassword: 'パスワードの確認',
};
