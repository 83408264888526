import * as Sentry from '@sentry/nextjs';
/**
 * Synchronously load the GMO MultiBilling SDK script into the document.
 */
export const loadGMOScript = (
	objectURL: string,
	onLoad: () => void,
	onError: () => void
): HTMLScriptElement => {
	const script = document.createElement('script');
	script.src = objectURL;
	script.async = false;

	script.onerror = (error) => {
		onError();
		Sentry.captureMessage('Error in loadGMOScript', { extra: { error } });
	};

	script.onload = onLoad;

	document.body.appendChild(script);

	return script;
};
