import { Card, Grid, Typography } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';
import { TFunction, Trans, useTranslation } from 'next-i18next';
import { ReactElement, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { PropertyType } from '@/components/forms/fields/AddressFields';
import { PostalAreaField } from '@/components/forms/fields/PostalAreaField';
import { PostcodeField } from '@/components/forms/fields/PostcodeField';
import { RadioWithBorder } from '@/components/shared/RadioWithBorder';
import {
	OCCTO_ADDRESS_LINE_1_MAX_LENGTH,
	OCCTO_ADDRESS_LINE_2_MAX_LENGTH,
} from '@/constants/constants';
import { PostalArea } from '@/services/typed-graphql-sdk';

function validateAddressLength(...parts: (string | undefined | null)[]) {
	return parts.filter((v) => v).join('').length <= 36;
}

type BillingAddressFieldsValidation = {
	billingAddressLine1: Yup.StringSchema<string | undefined, object>;
	billingBuildingName: Yup.StringSchema<string | undefined, object>;
	billingBuildingNumber: Yup.StringSchema<string | undefined, object>;
	billingRoomNumber: Yup.StringSchema<string | undefined, object>;
};

export const getBillingAddressFieldsValidation = (
	t: TFunction
): BillingAddressFieldsValidation => {
	return {
		billingAddressLine1: Yup.string()
			.trim()
			.required(t('errors.required'))
			.max(
				OCCTO_ADDRESS_LINE_1_MAX_LENGTH,
				t('errors.invalid-address-line1', {
					number: OCCTO_ADDRESS_LINE_1_MAX_LENGTH,
				})
			),

		billingBuildingName: Yup.string()
			.trim()
			.when(['billingPropertyType', 'isBillingAddressSameAsAddress'], {
				is: (billingPropertyType, isBillingAddressSameAsAddress) =>
					billingPropertyType === 'apartment' && !isBillingAddressSameAsAddress,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			})
			.test(
				'billingBuildingName',
				t('errors.invalid-address-line2', {
					number: OCCTO_ADDRESS_LINE_2_MAX_LENGTH,
				}),
				function (value) {
					return validateAddressLength(
						value,
						this.parent.BillingBuildingNumber,
						this.parent.billingRoomNumber
					);
				}
			),
		billingRoomNumber: Yup.string()
			.trim()
			.when(['billingPropertyType', 'isBillingAddressSameAsAddress'], {
				is: (billingPropertyType, isBillingAddressSameAsAddress) =>
					billingPropertyType === 'apartment' && !isBillingAddressSameAsAddress,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			})
			.test(
				'billingRoomNumber',
				t('errors.invalid-address-line2', {
					number: OCCTO_ADDRESS_LINE_2_MAX_LENGTH,
				}),
				function (value) {
					return validateAddressLength(
						value,
						this.parent.billingBuildingNumber,
						this.parent.billingBuildingName
					);
				}
			),
		billingBuildingNumber: Yup.string().test(
			'billingBuildingNumber',
			t('errors.invalid-address-line2', {
				number: OCCTO_ADDRESS_LINE_2_MAX_LENGTH,
			}),
			function (value) {
				return validateAddressLength(
					value,
					this.parent.billingRoomNumber,
					this.parent.billingBuildingName
				);
			}
		),
	};
};

type BillingAddressFields<T> = {
	billing?: boolean;
} & DomainFieldProps<T>;

export function BillingAddressFields<
	T extends {
		billingBuildingName: string;
		billingBuildingNumber: string;
		billingPostalArea: PostalArea;
		billingPostcode: string;
		billingPropertyType: PropertyType;
		billingRoomNumber: string;
		isBillingAddressSameAsAddress?: boolean;
		postalArea: PostalArea;
		postcode: string;
	},
>(
	props: BillingAddressFields<T> & {
		onPostalArea?: (postalArea: PostalArea) => void;
		postalAreas: PostalArea[];
	}
): ReactElement {
	const { t } = useTranslation();
	const { onPostalArea, values, billing = false, postalAreas } = props;

	const [showBillingPostalArea, setShowBillingPostalArea] = useState(false);
	useEffect(() => {
		setShowBillingPostalArea(
			billing && Boolean(values.billingPostalArea.postcode)
		);
	}, [values.billingPostalArea.postcode]);
	return (
		<>
			{!values.isBillingAddressSameAsAddress && (
				<div className="flex flex-col space-y-4 pt-4">
					<Card>
						<p className="text-lg">
							<Trans
								i18nKey={
									'obj:your-details.want-to-use-a-separate-billing-address'
								}
							/>
						</p>
					</Card>
					<Typography variant="h4">
						{t('obj:your-details.billing-address')}
					</Typography>
					<Grid gap="sm" templateColumns="repeat(2, 1fr)">
						<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
							<PostcodeField
								label={t('postcode')}
								billing={billing}
								{...props}
								onPostalArea={(postalArea) => {
									if (onPostalArea) {
										onPostalArea(postalArea);
									}
								}}
							/>
						</Grid.Item>
						<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
							{showBillingPostalArea && (
								<PostalAreaField
									name="billingPostalArea"
									initialValue={values.billingPostalArea}
									options={postalAreas}
									{...props}
								/>
							)}
						</Grid.Item>
					</Grid>
					<FormikTextField
						label={t('obj:inputs.address-line-1')}
						name="billingAddressLine1"
					/>
					<RadioWithBorder
						name="billingPropertyType"
						radioValues={[
							{
								label: t('obj:inputs.apartment'),
								value: 'apartment',
							},
							{
								label: t('obj:inputs.detached-house'),
								value: 'detachedHouse',
							},
						]}
					/>
					{values.billingPropertyType === 'apartment' ? (
						<>
							<FormikTextField
								label={t('obj:inputs.building-name')}
								name="billingBuildingName"
							/>
							<Grid gap="sm" templateColumns="repeat(2, 1fr)">
								<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
									<FormikTextField
										label={t('obj:inputs.building-number')}
										name="billingBuildingNumber"
										optional
									/>
								</Grid.Item>
								<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
									<FormikTextField
										label={t('obj:inputs.room-number')}
										name="billingRoomNumber"
									/>
								</Grid.Item>
							</Grid>
						</>
					) : null}
				</div>
			)}
		</>
	);
}
